import { 
  AudioConfig, 
  SpeechTranslationConfig, 
  SpeechRecognizer, 
  ResultReason, 
  OutputFormat, 
  PronunciationAssessmentConfig, 
  PronunciationAssessmentGradingSystem, 
  PronunciationAssessmentGranularity 
  } from 'microsoft-cognitiveservices-speech-sdk';

  export const SpeechToText = (file, referenceText='') => {
    return new Promise((resolve) => {
    
      const audioConfig = AudioConfig.fromWavFileInput(file);
      const speechConfig = SpeechTranslationConfig.fromSubscription(process.env.VUE_APP_AZURE_SPEECH_SUBSCRIPTION_KEY,process.env.VUE_APP_AZURE_SPEECH_SUBSCRIPTION_REGION);
      speechConfig.speechRecognitionLanguage = 'en-US';
      speechConfig.addTargetLanguage('Microsoft Server Speech Text to Speech Voice (en-IN, Heera, Apollo)');
      speechConfig.outputFormat = OutputFormat.Detailed;
      let recognizer = new SpeechRecognizer(speechConfig, audioConfig);
    

      let pronunciationAssessmentConfig = new PronunciationAssessmentConfig(referenceText,
            PronunciationAssessmentGradingSystem.HundredMark,
            PronunciationAssessmentGranularity.Word, true);
            
      pronunciationAssessmentConfig.applyTo(recognizer);
    
      recognizer.recognized = undefined;
    
      recognizer.recognizeOnceAsync(result => {
      
            if (result.reason === ResultReason.RecognizedSpeech) {
              resolve(JSON.parse(result.json));          
            } else {            
              resolve(false); 
            }
        });
    });
}



